

































































































































































































































































































































































































































































































































@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.team_routes_wrapper {
  display: flex;
  gap: 10px;
  justify-content: end;
  flex-wrap: wrap;
  margin-top: 20px;
  .back_link {
    color: #777;
    background-color: #eee;
    padding: 8px 10px;
    display: block;
    width: fit-content;
    margin: 10px 0;
    border-radius: 8px;
    transition: 0.3s;
    border: none;
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
}
// start collapse style
.main_statements_container {
  padding-top: 15px;

  .every_pattern_key {
    margin: 0 0 15px 0;
  }
  .second_list {
    list-style: none;
    &.second_with_rtl {
      text-align: right;
      padding-right: 40px;
      padding-left: 0;
    }
  }
}
::v-deep .el-checkbox-button {
  .el-checkbox-button__original {
  }
  .el-checkbox-button__inner {
    border: none;
    background: #fff !important;
    display: flex;
    gap: 8px;
    .span_in_check_statement {
      border: 1px solid #ccc;
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 4px;
      .checker {
        font-size: 9px;
        display: none;
      }
      &.with_true {
        background-color: #409eff;
        color: #fff;
        border: 1px solid #409eff;
        .checker {
          font-size: 9px;
          display: block;
        }
      }
    }
    &:hover {
      color: #000;
    }
  }
  &.is_all_checked {
    .el-checkbox-button__inner {
      // background: #77e311 !important;
      color: #409eff;
    }
  }
  .wrap_while_exist {
    color: #000;
    word-wrap: break-word;
    white-space: pre-wrap;
    text-align: left;
    line-height: 1.3;
    text-transform: initial;
  }
}
::v-deep .el-collapse-item__header {
  height: 70px;
}
.for_avoid_etc_words {
  color: #777;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 8px;
  position: relative;
}
// collapse style
/* mobile score progress design */
::v-deep .precision {
  margin: 5px;
  display: inline;
  background-color: #3c3;
}

::v-deep .technical_reasoning {
  display: inline;
  margin: 5px;

  background-color: #f93;
}

::v-deep .sequence {
  display: inline;
  margin: 5px;

  background-color: #39c;
}

::v-deep .confluence {
  display: inline;
  margin: 5px;

  background-color: #c36;
}
.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}
.letter {
  font-size: 40px;
}
.under_words {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: end;
  padding-top: 10px;
  &.is_rtl {
    flex-direction: row-reverse;
  }
  .icons_wrapping_edit_print {
    display: flex;
    align-items: center;
    gap: 20px;
    @media print {
      display: none;
    }
    .print_task_button {
      // background-color: #fff;
      color: #fff;
      border: none;
      box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s all ease-in-out;
      background-color: #11bfe3;
      .icons_actions {
        font-size: 14px;
      }

      &.edit_task_button {
        background-color: #ffa534;
      }
      &.share {
        background-color: #3c3;
      }
    }
  }
}
